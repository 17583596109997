import React, { useRef, useState } from 'react'
import './Style/Login.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Card, Alert, Button, TextField, Box, Typography, Grid, Avatar, Stepper, Step, StepLabel } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
export default function LoginPage() {

  const [activeStep, setActiveStep] = useState(0);
  const containerRef = useRef(null);
  const [formType, setFormType] = useState('register');
  const [otp, setOtp] = useState(["", "", "", ""]);

  const registerBtnClick = () => {
    containerRef.current.classList.add('active');
    setFormType('register');
  };

  const loginBtnClick = () => {
    containerRef.current.classList.remove('active');
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Register 

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    Confirm_Password: '',
    otp: "",
  });


  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate registration form inputs
    if (formType === 'register') {
      const { password, Confirm_Password } = formData;

      // Check if passwords match and all required fields are filled
      if (password !== '' && Confirm_Password !== '' && password === Confirm_Password) {
        axios
          .post("https://www.empairindia.com/details", formData)
          .then((response) => {
            console.log(response.data);
            console.log("Registration successfully completed!");
            setFormType('otp'); // Switch to OTP form on success
            containerRef.current.classList.add('active'); // Show the OTP form
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        alert('Passwords do not match or missing fields.');
      }
    } else if (formType === 'otp') {
      // Handle OTP submission
      console.log("OTP submitted");
      // Perform OTP verification or other actions
    }
  };

  const steps = ['Personal Information', 'Verification', 'Password'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Mobile Number"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) {  // Ensures only up to 10 digits can be entered
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    mobileNo: value,
                  }));
                }
              }}
              required
              InputProps={{
                style: { borderRadius: "8px" },
                inputProps: { maxLength: 10 },  // Ensures only 10 digits can be entered
              }}
            />
          </>
        );
      case 1:
        return (
          <>
            <Box display="flex" justifyContent="space-between" gap={1}>
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  id={`otp-${index}`}
                  variant="outlined"
                  margin="normal"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", borderRadius: "8px" }
                  }}
                  required
                />
              ))}
            </Box>
          </>
        );
     
      case 2:
        return (
          <>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formData.Confirm_Password}
              onChange={handleChange}
              required
              InputProps={{
                style: { borderRadius: "8px" }
              }}
            />
          </>
        );
      default:
        return 'Unknown step';
    }
  };



  // Login


  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    axios.post("https://www.empairindia.com/app/login", { email, password })
      .then((response) => {
        // console.log(response.data);
        // sessionStorage.setItem('User_id', User_id);
        setMessage('login successfull');
        window.location.href = '/Profile';
        // window.location.href = '/Profile';

      })
      .catch((error) => {
        setMessage('got errors');
        console.error(error);
      })
  };

  return (
    <div className='RegMain' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: '2rem' }}>
    <div className='logContainer' id='logContainer' ref={containerRef} style={{ width: '100%', maxWidth: '1200px', margin: '0 auto',height:'1000px' }}>
      <Card className="logContainer" style={{ width: '100%', padding: '2rem', marginLeft: '0', marginTop: '2rem' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar className="register-avatar" style={{ marginBottom: '1rem',marginLeft:"35rem" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="register-title" style={{ marginBottom: '1rem' ,marginLeft:"35rem" }}>
            Register
          </Typography>
          {message && <Alert severity="error" style={{ width: '100%', marginBottom: '1rem' }}>{message}</Alert>}
          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '600px', marginTop: '1rem',marginLeft:"35rem" }}>
            <Stepper activeStep={activeStep} style={{ marginBottom: '1rem' }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{ color: 'green' }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent(activeStep)}
            <Box display="flex" justifyContent="space-between" style={{ marginTop: '1rem' }}>
              {activeStep !== 0 && (
                <button onClick={handleBack} className="register-button">
                  Back
                </button>
              )}
              {activeStep === steps.length - 1 ? (
                <button type="submit" className="register-button">
                  Register
                </button>
              ) : (
                <button onClick={handleNext} className="register-button">
                  Next
                </button>
              )}
            </Box>
            <Grid container justifyContent="center" style={{ marginTop: '1rem' }}>
              <Grid item>
                <Link to="/login" className="link-text">
                  Already have an account? Login
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <div className='form-logContainer sign-in' style={{ marginTop: '2rem' }}>
        <form onSubmit={handleLoginSubmit} style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          <h1>Sign In</h1>
          <span>or use your email password</span>
          <input
            type="text"
            value={email}
            placeholder='Email'
            onChange={handleEmailChange}
            required
            style={{ width: '100%', marginBottom: '1rem' }}
          />
          <input
            type="password"
            placeholder='Password'
            value={password}
            onChange={handlePasswordChange}
            required
            style={{ width: '100%', marginBottom: '1rem' }}
          />
          <a href="#" style={{ marginBottom: '1rem' }}>Forget Your Password?</a>
          {message && <p>{message}</p>}
          <button onClick={handleLoginSubmit} style={{ width: '100%' }}>Sign In</button>
        </form>
      </div>
      <div className='toggle-logContainer'>
        <div className='toggle'>
          <div className='toggle-panel toggle-left'>
            <h1>Welcome Back!</h1>
            <p>Enter your personal details to use all of the site's features</p>
            <button className='hidden' id='login' onClick={loginBtnClick}>Sign In</button>
          </div>
          <div className='toggle-panel toggle-right'>
            <h1>Hello, Friend!</h1>
            <p>Register with your personal details to use all of the site's features</p>
            <button className='hidden' id='register' onClick={registerBtnClick}>Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  )
}
